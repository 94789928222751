.view-all-customers {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 100px;
    margin-left: 250px;
  }
  
  .view-all-customers h2 {
    color: #3498db; /* Header color */
    margin-bottom: 20px;
  }
  
  .view-all-customers table {
    max-width: 1200px;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .action-button {
    margin-left: 5px;
  }
  
  .view-all-customers th,
  .view-all-customers td {
    padding: 12px;
    text-align: center;
  }
  
  .view-all-customers th {
    background-color: #2c3e50; /* Header background color */
    color: #ecf0f1; /* Header text color */
    border: 1px solid #ddd;
  }
  
  .view-all-customers td {
    background-color: #f8f9fa; /* Cell background color */
    border: 1px solid #ddd;
  }
  
  .view-all-customers tbody tr:hover {
    background-color: #e9ecef; /* Hover background color */
  }
  
  .view-all-customers .btn-info {
    padding: 6px 12px; /* Adjust button padding */
  }  

  /* ViewAllServices.css */

.modal-dialog {
  max-width: 90%; /* Adjust maximum width of the modal dialog */
}

.modal-body {

  overflow-y: auto; /* Enable vertical scrolling */
}