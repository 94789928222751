.view-all-quotations {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 100px;
    margin-left: 250px;
  }
  
  .view-all-quotations h2 {
    color: #3498db; /* Header color */
    margin-bottom: 20px;
  }
  
  .view-all-quotations table {
    max-width: 1200px;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .action-button {
    margin-left: 5px;
  }
  
  .view-all-quotations th,
  .view-all-quotations td {
    padding: 12px;
    text-align: center;
  }
  
  .view-all-quotations th {
    background-color: #2c3e50; /* Header background color */
    color: #ecf0f1; /* Header text color */
    border: 1px solid #ddd;
  }
  
  .view-all-quotations td {
    background-color: #f8f9fa; /* Cell background color */
    border: 1px solid #ddd;
  }
  
  .view-all-quotations tbody tr:hover {
    background-color: #e9ecef; /* Hover background color */
  }
  
  .view-all-quotations .btn-info {
    padding: 6px 12px; /* Adjust button padding */
  }  

  /* ViewAllServices.css */

.modal-dialog {
  max-width: 90%; /* Adjust maximum width of the modal dialog */
}

.modal-body {

  overflow-y: auto; /* Enable vertical scrolling */
}

.invoice-container {
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.invoice-header h1 {
  font-size: 24px;
  margin: 0;
}

.invoice-header p {
  margin: 0;
}

.invoice-details {
  margin-bottom: 20px;
}

.invoice-details p {
  margin: 0 0 5px;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.invoice-table th {
  background-color: #f2f2f2;
}

.invoice-total {
  text-align: right;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}