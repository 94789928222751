/* Reset default margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa; /* Light background color */
}

/* Container for the entire application */
.App {
  display: flex;
  min-height: 100vh; /* Minimum full viewport height */
}

/* Sidebar styles */
.sidebar {
  background: linear-gradient(to right, #261d30, #19325f);
  color: #ecf0f1;
  height: 100vh;
  width: 250px;
  position: fixed;
  left: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1; /* Ensure sidebar stays above content */
}

.sidebar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  margin-top: -47px;
}

.sidebar-logo img {
  width: 270px;
  height: auto;
}

.sidebar .nav-link {
  color: #ecf0f1;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 12px 20px;
  margin: 5px 0;
  border-radius: 5px;
}

.sidebar .nav-link:hover,
.sidebar .nav-link.active {
  color: #ffffff;
  background-color: #2b0653;
}

.sidebar .dropdown-toggle {
  color: #ecf0f1;
  background-color: transparent;
  border: none;
  padding: 12px 20px;
  margin: 5px 0;
  border-radius: 5px;
  text-decoration: none;
}

.sidebar .dropdown-toggle:hover {
  color: #ffffff;
  background-color: #2b0653;
  text-decoration: none;
}

.sidebar .dropdown-menu {
  background-color: #19325f;
  border: none;
  margin-left: 10px;
  width: 220px;
  text-decoration: none;
}

.sidebar .dropdown-item {
  color: #ecf0f1;
  padding: 10px 20px;
  text-decoration: none;
}

.sidebar .dropdown-item:hover {
  background-color: #2b0653;
}

/* Sidebar header styles */
.sidebar-header {
  padding: 20px;
  text-align: center;
  background-color: #212529; /* Darker header background color */
}

/* Sidebar navigation links styles */
.sidebar-nav {
  flex: 1; /* Take remaining vertical space */
  overflow-y: auto; /* Allow scrolling if content exceeds height */
}

.sidebar-nav a {
  display: block;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.sidebar-nav a:hover {
  background-color: #495057; /* Darken background on hover */
}

/* Content area styles */
.content {
  flex: 1; /* Take remaining horizontal space */
  margin-left: 250px; /* Adjust for sidebar width */
  padding: 20px;
  overflow-y: auto; /* Enable scrolling for content area */
}

/* Full-width content styles */
.full-width {
  margin-left: 0; /* No margin for full-width content */
  padding: 0;
  background: none;
  flex: 1; /* Ensure the content fills the available space */
  display: flex; /* Flexbox to center the content */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

/* Footer styles */
.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
}

.footer a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

.footer a:hover {
  text-decoration: underline;
}

/* Loading screen styles */
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa; /* Light background color */
}

/* Media Query for responsiveness */
@media (max-width: 768px) {
  .sidebar {
    width: 100%; /* Full width on smaller screens */
  }

  .content {
    margin-left: 0; /* No margin for content */
  }
}

/* Font styles for headings */
h1, h2, h3 {
  font-family: Arial, sans-serif;
}

/* Font styles for table headers and cells */
th, td {
  font-family: Arial, sans-serif;
  font-size: 14px;
}