.navbar {
  background: linear-gradient(to left, #261d30, #19325f);
  color: #ecf0f1;
  width: calc(100% - 250px);
  margin-left: 230px;
  position: fixed;
  top: 0;
  z-index: 1000;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}

.navbar .nav-link {
  color: #ecf0f1;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 15px 20px;
  margin: 5px 20px;
  border-radius: 5px;
}

.navbar .nav-link:hover,
.navbar .nav-link.active {
  color: #ffffff;
  background-color: #2b0653;
}

@media (max-width: 768px) {
  .navbar {
    width: 100%;
    margin-left: 0;
  }
}