.view-staff-container {
  background-color: #ffffff; /* White background */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: auto; /* Center container horizontally */
}

.page-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 32px;
  color: #343a40; /* Dark gray color */
}

.staff-details {
  background-color: #ffffff; /* White background */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.staff-details table {
  width: 100%;
  border-collapse: collapse;
}

.staff-details td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.staff-details td:first-child {
  font-weight: bold;
}

.staff-details td:last-child {
  text-align: right;
}

.loading-message {
  text-align: center;
  font-size: 20px;
  color: #6c757d;
}