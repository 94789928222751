/* CreateService.css */
.create-staff {
    width: 800px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 86px;
    background-color: #f2f2f2;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-left: 470px;
  }
  
  .create-staff h2 {
    color: #333;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  input[type='text'],
  input[type='number'] {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }
  
  input[type='text']:focus,
  input[type='number']:focus {
    outline: none;
    border-color: #1abc9c;
  }
  
  .create-button[type='submit'] {
    background-color: #1abc9c;
    color: #fff;
    border: none;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .create-button[type='submit']:hover {
    background-color: #148f77;
  }  