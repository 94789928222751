/* src/Invoice.css */

.invoice-container {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.company-details h1 {
  font-size: 24px;
  margin: 0;
}

.bill-to h4 {
  margin: 0;
  font-weight: normal;
}

.invoice-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.invoice-info div {
  text-align: center;
}

.amount-due {
  background-color: #f8f8f8;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.invoice-summary {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.invoice-summary div {
  text-align: right;
  margin-left: 20px;
}

.total-amount {
  font-weight: bold;
}

.invoice-notes h4 {
  margin-top: 0;
}

.invoice-footer {
  text-align: center;
  font-size: 12px;
  color: #888;
  margin-top: 20px;
}

.print-button-container {
  text-align: center;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .invoice-container {
    padding: 10px;
  }

  .company-details h1 {
    font-size: 20px;
  }

  .bill-to h4 {
    font-size: 16px;
  }

  .invoice-info div {
    flex-basis: 25%;
  }
}