.scrollable-list {
  max-height: 300px; /* Adjust height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 10px; /* Add padding to avoid content being hidden under the scrollbar */
}

.scrollable-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.scrollable-list li {
  margin-bottom: 10px; /* Add some spacing between list items */
}

.scrollable-list p {
  margin: 0; /* Remove margin from paragraph elements inside list items */
}