.view-quotation-container {
  background-color: #ffffff; /* White background */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: auto; /* Center container horizontally */
}

.page-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 32px;
  color: #343a40; /* Dark gray color */
}

.details-card {
  background-color: #ffffff; /* White background */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.details-card table {
  width: 100%;
  margin-bottom: 20px;
}

.details-card th, .details-card td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.details-card th {
  background-color: #f8f9fa;
}

.loading-message {
  text-align: center;
  font-size: 20px;
  color: #6c757d;
}