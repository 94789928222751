.Signin {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin-left: 250px;
}
  
.login-container {
    background: linear-gradient(to right, #261d30, #19325f);
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 25px; /* Remove auto margin */
}
  
.logo-container {
    text-align: center;
    margin-right: 200px;
}
  
.logo {
    width: 250px;
}
  
.form-group {
    margin-bottom: 20px;
}
  
.form-group label {
    display: block;
    margin-bottom: 5px;
}
  
.form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
  
.signin-btn {
    width: 100%;
    padding: 10px;
    background: #16121c;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
  
.signin-btn:hover {
    background: #285bbb;
}
  
.forgot-password {
    margin-top: 10px;
    font-size: 14px;
    text-align: right;
}
  
a {
    color: #261d30;
    text-decoration: none;
}
  
a:hover {
    text-decoration: underline;
}