/* UpdateStaff.css */
.update-staff {
  width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 176px;
  background-color: #f2f2f2;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 470px;
}

.update-staff h2 {
  color: #333;
  text-align: center;
}

.update-staff form {
  margin-top: 20px;
}

.update-staff label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
}

.update-staff input[type='text'],
.update-staff input[type='email'],
.update-staff input[type='password'],
.update-staff input[type='number'] {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.update-staff input[type='text']:focus,
.update-staff input[type='email']:focus,
.update-staff input[type='password']:focus,
.update-staff input[type='number']:focus {
  outline: none;
  border-color: #1abc9c;
}

.update-staff button[type='submit'] {
  background-color: #1abc9c;
  color: #fff;
  border: none;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.update-staff button[type='submit']:hover {
  background-color: #148f77;
}

.update-staff .mt-3 {
  margin-top: 20px;
}