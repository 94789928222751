.dashboard {
  padding: 20px;
  margin-top: 76px; /* Adjust based on your navbar height */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 78px; /* Adjust based on your footer height */
  margin-left: 250px; /* Adjust based on your sidebar width */
}

.info-card1, .info-card2, .info-card3, .info-card4, .info-card5 {
  text-align: center;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.info-card1 {
  background-color: green;
  color: #ecf0f1;
}

.info-card2 {
  background-color: red;
  color: #ecf0f1;
}

.info-card3 {
  background-color: orange;
  color: #ecf0f1;
}

.info-card4 {
  background-color: rgb(255, 0, 128);
  color: #ecf0f1;
}

.info-card5 {
  background-color: rgb(0, 195, 255);
  color: #ecf0f1;
}

/* Hover effects for cards */
.info-card1:hover, .info-card2:hover, .info-card3:hover, .info-card4:hover, .info-card5:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.charts-section {
  margin-bottom: 20px;
}

.chart-container {
  background-color: #ffffff;
  padding: 7px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chart-container-pie {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-legend {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.legend-color {
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 5px;
}

.chart-title {
  font-size: 15px;
}

.table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.table th {
  background-color: #f8f9fa;
  text-align: center;
  color: #333;
}

.table td {
  text-align: center;
  color: #555;
}