.invoice-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}
 
.invoice-header {
  text-align: center;
  margin-bottom: 20px;
}
 
.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
 
.invoice-table th, .invoice-table td {
  border-right: 2px solid #000000; /* Add right border for columns */
  padding: 8px;
  line-height: 0.8;
  text-transform: capitalize;
}

.invoice-table th {
  background-color: #000000;
  font-weight: bold;
}

.invoice-table tr {
  border-bottom: none; /* Remove horizontal lines between rows */
}

.invoice-table thead tr th {
  border-bottom: 2px solid #000000; /* Keep a border line for the header row if needed */
  border-top: 2px solid #000000;
  border-left: 2px solid #000000;
}

.invoice-table tbody tr td {
  border-bottom: 2px solid #ffffff; /* Keep a border line for the header row if needed */
  border-top: 2px solid #ffffff;
  border-left: 2px solid #000000;
}

.customer-details {
  border: 1px solid #000; /* Border thickness and color */
  border-radius: 20px; /* Curve radius for the corners */
  margin-left: 47px; /* Space below the customer details section */
  background-color: #f9f9f9; /* Optional: Background color for the customer details */
}
 
.company-details {
  margin-left: 125px;
}

 
.signature-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding: 10px;
}
 
.authorized-signature,
.customer-signature {
  flex: 1;
}
 
.signature-line {
  margin-top: 20px;
  border-top: 1px solid #000;
  width: 100%;
}
 
.total-text {
  font-weight: bold;
}
 
.total-amount {
  font-weight: bold;
  text-align: right;
}
 
@media print {
  .invoice-container {
      padding: 0;
  }

  .invoice-table {
      page-break-inside: auto;
  }

  .invoice-table tr {
      page-break-inside: avoid;
      page-break-after: auto;
  }

  .signature-section {
      position: fixed;
      bottom: 0;
      width: 100%;
      border-top: 1px solid #ddd;
      padding: 10px;
      background: #fff; /* Ensure it's visible */
  }
}