/* UpdateService.css */
.update-service {
  width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 176px;
  background-color: #f2f2f2;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 470px;
}

.update-service h2 {
  color: #333;
  text-align: center;
}

.update-service form {
  margin-top: 20px;
}

.update-service label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
}

.update-service input[type='text'],
.update-service input[type='number'] {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.update-service input[type='text']:focus,
.update-service input[type='number']:focus {
  outline: none;
  border-color: #1abc9c;
}

.update-service button[type='submit'],
.update-service button[type='button'] {
  background-color: #1abc9c;
  color: #fff;
  border: none;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.update-service button[type='submit']:hover,
.update-service button[type='button']:hover {
  background-color: #148f77;
}

.update-service fieldset {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.update-service legend {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}

.update-service .task-item {
  margin-bottom: 15px;
}