/* Profile.css */

.profile {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 970px;
    margin-top: 100px;
    margin-left: 347px;
  }
  
  .profile h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .profile .form-group {
    margin-bottom: 20px;
  }
  
  .profile .form-label {
    font-weight: bold;
  }
  
  .profile .form-control[readonly] {
    background-color: #ffffff;
    border: none;
    box-shadow: none;
  }
  
  .profile .form-control[readonly]:focus {
    box-shadow: none;
  }
  
  .profile .mb-3 {
    margin-bottom: 1rem;
  }
  
  .profile .update-button {
    margin-top: 20px;
    width: 100%;
  }  