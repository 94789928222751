/* ViewService.css */

.view-service-container {
  padding: 2rem;
}

.page-title {
  margin-bottom: 2rem;
}

.error-message {
  color: #dc3545; /* Bootstrap danger color */
  font-size: 1.2rem;
}

.loading-message {
  color: #17a2b8; /* Bootstrap info color */
  font-size: 1.2rem;
}

.details-card {
  border-radius: 10px;
  border: 1px solid #ced4da;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.details-card .card-body {
  padding: 2rem;
}

.table {
  margin-bottom: 1rem;
}

.table th {
  background-color: #f8f9fa; /* Light grey background */
  color: #495057; /* Dark grey text */
}

.table td {
  background-color: #ffffff; /* White background */
}

.table-bordered th,
.table-bordered td {
  border-color: #000000; /* Border color matching the primary color */
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  text-align: center;
}