.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: #fff; /* Background color */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db; /* Loader color */
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Animation for spinning */
  margin-right: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-screen p {
  font-size: 24px;
}