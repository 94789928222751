/* General container styling */
.container {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 25px;
  width: 100%px;
  box-sizing: border-box; /* Includes padding and border in width calculation */
  margin-left: 270px;
  margin-right: 75px;
  margin-top: 125px;
  margin-bottom: 25px;
}

/* Heading styling */
h2 {
  color: #3498db; /* Header color */
  margin-bottom: 20px;
  text-align: center;
}

/* Search bar styling */
.search-bar {
  margin-bottom: 20px;
}

/* Table styling */
.table {
  margin-top: 20px;
  width: 100%; /* Ensures the table takes full width of its container */
  border-collapse: collapse;
  margin-bottom: 20px;
}

.table th {
  background-color: #f8f9fa;
  color: #333;
}

.table td {
  vertical-align: middle;
}

/* Loading spinner styling */
.spinner-border {
  margin-top: 20px;
}

/* Alert styling */
.alert {
  margin-top: 20px;
}

/* Chart container styling */
.chart-container {
  margin-bottom: 40px;
  width: 100%; /* Ensures chart takes full width of its container */
  max-width: 1000px; /* Matches the container width */
}